.manage_profile_container {
  width: 100%;
  height: 100%;
  background-color: #f0f3f4;
  display: flex;
  flex-direction: column;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover {
  color: #ce993b !important;
}
.ant-tabs-ink-bar {
  background: #ce993b !important;
}
.ant-tabs{
    flex: 1 !important;    
    
}
.tab_container{
    margin: auto;
    border-radius: 8px;
    max-width: 800px;
    background-color: white;   
}