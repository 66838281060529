.merchant_detaill_container .ant-descriptions-item-label {
  color: #9f9e9f !important;
}

.merchant_stat_card {
  height: 150px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 16px #d3d3d3, -5px -5px 16px #ededed;
}
.merchant_stat_card > div {
  margin-top: auto;
}

.merchant_stat_card {
  padding: 20px;
}
.merchant_stat_card h5 {
  font-size: 18px;
  color: #9f9e9f !important;
  font-family: "Poppins-Bold";
}
.merchant_stat_card img {
  width: 50px;
  height: 50px;
}
.merchant_stat_card p {
  margin-bottom: 0;
  font-size: 28px;
  font-family: "Poppins-Semibold";
}
