.sidebar {
  height: calc(100vh - 60px);
  background-color: #333333;
  overflow: hidden;
}
.sidebar_links_container {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
.sidebar_links_container a {
  text-decoration: none;
  color: #ce993b;
  width: 100%;
  padding: 15px 30px;
  transition: 0.3s all;
  font-family: "Poppins-Semibold" !important;
  font-size: 12px;
}
.sidebar_links_container svg{
  margin-right: 10px;
}

.sidebar_links_container svg path{
  fill: #ce993b !important;
}

.sidebar_links_container a.active,
.sidebar_links_container a:hover {
  background-color: #ce993b;
  color: black;
}


.sidebar_links_container a.active svg path,
.sidebar_links_container a:hover svg path{
  fill: black !important;
}