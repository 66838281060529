.login_container {
  height: 100%;
  background-color: #262626;
  position: relative;
  overflow-y: auto;
}
.dd_logo {
  position: absolute;
  right: 0;
  top: -50px;
  max-width: 100%;
  height: auto;
  z-index: 0;
}
