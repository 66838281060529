.header_ {
  background-color: #ce993b;
  height: 60px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo_text {
  font-family: "Titillium-Bold" !important;
  color: #333333;
  font-size: 30px;
  margin: 0;
  cursor: pointer;
}
.profile_ {
  width: 50px;
  height: 50px;
  background: #333333;
  border-radius: 50%;
}
.ant-dropdown-menu-title-content > a {
  font-size: 12px;
  font-family: "Poppins-Medium";
}
.username_ {
  margin: 0 10px 0 0;
  font-size: 14px;
  text-transform: capitalize;
}
.logout_ {
  font-size: 12px;
  font-family: "Poppins-Medium";
  margin: 0 5px 0 0;
}
