.input_field {
  height: 60px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  padding: 10px;
}
.input_field label {
  color: #000000;
  font-size: 11px;
  font-family: 'Poppins-Medium';
}

.input_field input {
  border: 0;
  outline: none;
}
.input_field input::placeholder{
  color: #C7C7C7;
  font-size: 13px;
  font-family: 'Poppins-Medium';
}