.dashboard_card {
  height: 130px;
  background-color: white;
  padding: 20px;
  border-radius: 11px;
  background: #fdfdfd;
  box-shadow: 5px 5px 16px #d3d3d3, -5px -5px 16px #ededed;
}
.dashboard_card_label {
  font-size: 14px;
  font-family: "Poppins-Bold";

}
.dashboard_card_value {
  font-size: 44px;
}
.dashboard_container .ant-picker:hover,
.ant-picker-focused {
  border-color: #ce993b !important;
  border-right-width: 1px !important;
  box-shadow: none !important;
}
.dashboard_container .ant-picker-active-bar {
  background: transparent !important;
}

.dashboard_container
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
.dashboard_container
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #ce993b !important;
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: #ce993b !important;
}
