.ant-modal-title{
    font-size: 20px !important;
    font-family: 'Poppins-Bold' !important;
}

.change_password_container label {
  font-size: 12px;
  font-family: "Poppins-Medium";
}

.change_password_container input {
  font-size: 14px;
}

.change_password_container input::placeholder {
  font-size: 10px;
}
