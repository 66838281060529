.loginform_container {
  max-width: 400px;
  height: 540px;
  background-color: rgba(255, 255, 255, 0.35);
  border: 1px solid #cecece;
  border-radius: 12px;
  backdrop-filter: blur(8px);
  padding: 30px 30px;
  z-index: 2;
  position: relative;
}
