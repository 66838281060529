.signup_page_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.signup_header{
  background-color: black;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
}

.signup_forms_container {
  flex: 1;
  display: flex;
  background-color: #f6f6f6;
}
.signup_forms_container > div {
  width: 50%;
  margin: 20px auto;
  background-color: white;
  padding: 30px 50px;
}
.form_heading {
  font-family: "Poppins-Bold" !important;
  font-size: 24px;
  text-align: center;
  margin-bottom: 30px;
}

.signup_forms_container label {
  font-size: 12px;
  font-family: "Poppins-Medium";
}

.signup_forms_container input {
  font-size: 14px;
}

.signup_forms_container input::placeholder {
  font-size: 10px;
}

.signup_forms_container table {
  text-align: center;
}
.signup_forms_container table th {
  font-size: 14px;
}


.signup_forms_container table tr {
  font-size: 12px;
}


.invalid-feedback {
  font-size: 10px !important;
}
