.upload_div {
  height: 250px;
  border: 1px dashed rgba(0, 0, 0, 0.5);
  width: 250px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  position: relative;
}
