.merchant_listing_container table thead th{
    font-family: 'Poppins-Bold';
    font-size: 14px;
    color: #9f9e9f !important;
}

.merchant_listing_container table tbody td{
    font-size: 12px;
}

.ant-table-small .ant-table-thead > tr > th {
    background-color: white !important;
}
.antd_table_row{
    cursor: pointer !important;
}