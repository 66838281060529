@font-face {
  font-family: Poppins-Regular;
  src: url("../assets/fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("../assets/fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url("../assets/fonts/poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: Poppins-Bold;
  src: url("../assets/fonts/poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: Titillium-Bold;
  src: url("../assets/fonts/titillium/TitilliumWeb-Bold.ttf");
}

* {
  box-sizing: border-box;
  font-family: "Poppins-Regular";
}

/* set pointer */

.is_pointer {
  cursor: pointer;
}


/* text fonts */

.bold {
  font-family: "Poppins-Bold" !important;
}
.medium {
  font-family: "Poppins-Medium" !important;
}

/* text colors */

.greyText {
  color: #9f9e9f;
}

.whiteText {
  color: white;
}

.yellowText {
  color: #ce993b;
}

.font_14 {
  font-size: 14px;
}

.font_12 {
  font-size: 12px;
}

/* .btn:focus , input:focus {
  outline: none;
  box-shadow: none;
} */
.subcontent_heading{
  text-transform: uppercase;
  font-size: 30px;
  font-family: 'Poppins-Bold' !important;
  margin: 0;
  width: fit-content;
}

.welcometext {
  font-family: "Titillium-Bold" !important;
  color: white;
  font-family: 44px;
  margin-bottom: 56px;
}
.welcometext mark {
  color: #ce993b;
  background-color: transparent;
  font-family: "Titillium-Bold" !important;
}

.ant-spin-dot-item {
  background-color: black !important;
}
.profile_label{
  color: black;
  font-family: 'Poppins-Semibold';
  font-size: 12px;
}
.profile_value{
  font-size: 20px;
  color: #00000073;
  font-family: 'Poppins-Semibold';
}