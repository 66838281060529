.delete_icon {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
